@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

@mixin button {
    border-width: 0;
    display: inline-block;
    padding: $buttonPad;
    font-size: 0.9rem;
    position: relative;
    color: #fff;
    background: $color-jabil-blue;
    border: 1px solid $color-jabil-blue;
    text-decoration: none;
    text-transform: uppercase;
    min-width: 250px;
    text-align: center;
    background-image: linear-gradient(45deg, scale-color($color-jabil-blue, $lightness: -60%) 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: all 0.5s ease-out;

    &:hover {
        background-position: 0;
    }
}

.button {
    @include button();

    @include forColors() using ($color, $value) {
        &-#{$color} {
            background-color: $color;
        }
    }
}

.page__wrap .ctaList .button {
    border: 1px solid $color-white;
}

.button__icon {
    display: inline-block;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);

    &--download {
        fill: #fff;
        height: 15px;
    }

    &--pdf {
        height: 40px;
    }
}

.button__iconContainer {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 10px;
}

.button--large {
    padding: 18px 60px;
    font-size: 1.2rem;
    margin: 20px auto;
    min-width: 50%;
}

.button--bottomMargin {
    margin-bottom: 35px;
}

@include forColors() using ($color, $value) {
    .colorTheme--#{$color} {
        .button {
            background-color: rgba($value, 1);
            border-color: rgba($value, 1);
            background-image: linear-gradient(45deg, scale-color($value, $lightness: -60%) 50%, transparent 50%);
        }

        .button:hover {
            background-position: 0;
        }

        .button--hollow {
            background-color: transparent;
            border: 1px solid $color-white;
            overflow: hidden;
            color: $color-jabil-dark-blue2;

            &:hover {
                background-image: linear-gradient(45deg, scale-color($value, $lightness: -60%) 50%, transparent 50%);
                cursor: pointer;
            }

        }

        .articleHeadline--tinted .button--hollow.button--hollowPop {
            background-color: $color-white;
        }


        .button--hollow.button--hollowPop {
            border: 1px solid $value;
            color: $value;

            &:hover {
                background-image: linear-gradient(45deg, scale-color($value, $lightness: -60%) 50%, transparent 50%);
                color: $color-white;
            }
        }

        .is-invalid-link,
        .is-invalid-link:hover {
            background-image: none;
            background-position: unset;
        }
    }

    .theme--light .colorTheme--#{$color} .button--hollow {
        color: $value;
    }

    .button--#{$color} {
        background-color: $value !important;
        border-color: $value !important;
        background-image: linear-gradient(45deg, scale-color($value, $lightness: -40%) 50%, transparent 50%) !important;
    }
}

.theme--dark {
    .button {
        color: $color-white;
    }
}

.theme--light {
    .button {
        color: $color-black;
        &:hover {
            color: $color-white;
        }
    }
}

@include breakpoint(md) {
    .columns--1-up {
        .button.button--widen {
            min-width: 300px;
            max-width: fit-content;
            padding-left: 48px;
            padding-right: 48px;
        }
    }

    .columns--2-up,
    .columns--3-up,
    .columns--4-up {
        .button.button--widen {
            max-width: 100%;
        }
    }
}
