@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

@use "navLink";
@use "navLang";
@use "navStock";

header {
  position: relative;
}

.nav {
    font-family: $font-lato;
    background-color: $color-jabil-darker-blue;
    margin: auto;
    // position: fixed;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;

    @include breakpoint(lg) {
      border-top: 14px solid #46bfe8;
      border-bottom: 9px solid #fff;
    }

    > .wrap {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;

        @include breakpoint(lg) {
            flex-direction: row;
            height: auto;
            align-items: flex-end;
            overflow: visible;
            padding: 10px 2rem;
        }
    }

    .nav__right {
        display: none;
        @include breakpoint(lg) {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            align-self: center;
            flex-wrap: wrap;
        }
    }

    .nav__right--relative {
        position: relative;
    }

    .nav__homeImg {
        width: 8rem;
        z-index: 4;
        padding: 15px 0;

        @include breakpoint(lg) {
            padding: 0;
            margin-bottom: .9rem;
        }
    }

    .navLink__title {
        padding: 12px 30px;
        font-size: 0.8rem;

        @media screen and (max-width: 1112px) {
            .jabil-one-mx & {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    .navLink__item {
        //pointerevent not allowed on spans for touch
        span {
            pointer-events: none;
        }
    }
}
@media only screen and (max-width: 1023px) {
    .nav {
        background: $color-jabil-dark-blue;
    }
}
.nav.scrolled {
  position: fixed;
  top: 0;
  background: rgba(0,43,73,.9)
}

.nav.scrolled .nav__pages {
  flex: 1 1 auto;
  order: 0;
}

.scrolled {
  .investorBar {
    display: none;
  }
}

.nav__pages {
    opacity: 1;
    transition: opacity ease 0.2s;
    width: auto;
    flex: 1 1 90%;
    order: 1;
}

.nav--pushToBack {
    z-index: 0;
}

.nav__search {
    position: absolute;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(70%);
    width: 50%;
    right: 0;

    .nav__searchForm {
        position: relative;

        .nav__searchForm__searchIcon {
            position: absolute;
            top: 50%;
            left: 15px;
            height: 24px;
            width: 24px;
            transform: translateY(-50%);
        }
    }

    input {
        width: 100%;
        background: transparent;
        color: $color-white;
        border: none;
        outline: none;
        padding-left: 50px;
        font-size: 18px;

        &::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }
    }
}

.nav__search--active {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.1s;
    transform: translateX(0%);
    top: 20%;

    .investorBarAlt {
      visibility: hidden;
      opacity: 0;
    }
}

.nav__mobileBtn,
.nav__closeBtn {
    border: 0;
    position: fixed;
    top: 0;
    right: 0;
    width: 56px;
    background: transparent;
    padding: 10px;
    cursor: pointer;

    @include breakpoint(lg) {
        display: none;
    }
}

.nav__closeBtn {
    visibility: hidden;
}

.nav__searchIcon {
  order: 3;
    > img {
        height: 24px;
        width: 24px;
        padding: 10px 5px;
        box-sizing: content-box;
    }
}

.nav__closeIcon {
    position: absolute;
    right: 0;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    transform: translateY(-50%);

    img {
        width: 28px;
    }
}

.nav__closeIcon--active {
    opacity: 1;
    visibility: visible;
}

.nav__mobileOverlay {
    display: block;
    width: 100%;
    right: -100%;
    top: 0;
    padding-top: 55px;
    position: fixed;
    background-color: $color-black-transparent-90;
    transition: ease-in 0.2s right;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 4em;

    .nav__search {
        padding: 15px;
        border-bottom: 1px solid $color-white-transparent-20;
        position: static;
    }

    .nav__search--active {
        transform: translateX(0%);
        width: 100%;
    }

    .nav__searchIcon {
        display: none;
    }

    .navLink {
        padding: 0;
        margin: 0;
        display: block;
        border-top: 1px solid $color-white-transparent-20;
    }

    .navLink > li {
        border-bottom: 1px solid $color-white-transparent-20;
        padding: 0;
        overflow: hidden;
        justify-content: space-between;
    }

    .navLink__title {
        display: flex;
        justify-content: center;
        padding: 15px 25px;
    }

    .navLink__trigger {
        display: none;

        &--hasSubnav {
            display: flex;
        }
    }

    .navLink__item--active .navLink__title {
        border-bottom: 1px solid $color-white-transparent-20;
    }

    li:hover .navLink__title {
        background: none;

        @include breakpoint(lg) {
            opacity: 0;
        }
    }

    .wrap {
        display: block;
    }

    .navLink__submenus {
        width: 100%;
        margin-left: 0;
    }

    .subNavLink__title--active {
        border-color: transparent;
    }

    @include breakpoint(lg) {
        display: none;
    }
}

.nav__homeImg--mobile {
    max-width: 110px;
    position: absolute;
    top: 1rem;
    left: 15px;
}

.nav--active {
    .nav__mobileOverlay {
        right: 0;
    }

    .nav__mobileBtn {
        visibility: hidden;
    }

    .nav__searchIcon {
        display: none;
    }

    .nav__closeBtn {
        z-index: 6;
        visibility: visible;
    }
}
/* stylelint-disable */
.nav--home {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    // .nav__left {
    //     display: none;
    // }

    .nav__mobileOverlay {
        .nav__search {
            display: block;
        }
    }

    .nav__right {
        margin: 0 auto;
    }
}

.nav--homeStuck {
    position: fixed;
    opacity: 0;
    transform: translateY(-100%);

    .nav__search,
    .nav__left,
    .nav__searchIcon {
        display: block;
    }

    .nav__mobileOverlay {
        .nav__searchIcon {
            display: none;
        }
    }

    .nav__right {
        margin: initial;
    }
}

.nav--homeHasAnim {
    transition: all 0.2s ease-in;
}

.nav--homeStuckOpen {
    transition: all 0.2s ease-in;
    position: fixed;
    opacity: 1;
    transform: translateY(0);
}
/* stylelint-enabled */

.magnolia-editmode {
    .nav {
        position: static;
    }

    .nav__searchIcon {
        display: none;
    }

    .mgnlEditorBarStatusIndicator ~ header .nav > .wrap {
        display: block;
    }

    .mgnlEditorBarStatusIndicator ~ header .nav__right {
        display: block;
    }

    .mgnlEditorBarStatusIndicator ~ header .nav__pages {
        margin: 20px 0;
    }
}

.nav__mobileOverlay .navLink__subnav {
    background: $color-black-transparent-90;
}

.nav__mobileOverlay li:hover .navLink__title {
    background-color: transparent;
}

.nav__search--active {
    .nav__pages {
        opacity: 0;
    }
}

//investor bar
.investorBar {
    transition: 0.2s;
    transform: scale(1);
    font-size: 0.9rem;
    width: 100%;

    a {
        color: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:hover {
            color: $color-jabil-light-blue;
        }
    }
}

.investorBar--emphasized a {
    font-weight: 700;
}

.investorBar--mobile {
    display: flex;

    .investorBar {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 0.9rem;

        .wrap {
            padding: 0;
            margin: 0 auto;
            width: 100%;
        }

        .investorWrap {
            a {
                padding: 0.8em;
                border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
            }
        }
    }

    @include breakpoint(lg) {
        display: none;
    }
}

.investorBar--desktop {
    display: none;

    @include breakpoint(lg) {
        display: flex;

        .investorBar {
            position: absolute;
            top: 44px;
            left: 0;
            height: 36px;
            background-color: rgba(35, 35, 35, 0.85);
            z-index: 2;
            font-size: 0.9rem;
            // display: none;

            > .wrap {
                display: flex;
            }

            .investorWrap {
                margin: 0 auto;
                display: flex;
                min-width: 950px;
                height: 36px;

                &.singleLink {
                    a:nth-of-type(1) {
                        align-items: center;
                    }
                }

                &.doubleLink {
                    a:nth-of-type(1) {
                        padding-right: 25px;
                    }

                    align-items: center;
                }

                a {
                    color: #fff;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-decoration: none;

                    &:hover {
                        color: $color-jabil-light-blue;
                    }
                }
            }

            a {
                z-index: 3;
            }
        }

        .investorBar--emphasized {
            height: 46px;
            font-size: 1.1em;
            background: rgba($color-jabil-dark-blue, 0.7);

            .investorWrap {
                height: 46px;
            }

            &:hover {
                background: rgba($color-jabil-dark-blue, 0.9);
            }
        }
    }
}

.investorBarAlt {
  @include breakpoint(lg) {
    width: auto;
    align-self: center;
  }
  transition: all .3s linear;
}

.navLangWrap__mobile {
    .navLink__title {
        @media (max-width: $width-tablet-max) {
            justify-content: flex-start;
        }
    }
}

.magnolia-editmode {
    .investorBar {
        display: none;
    }
}
