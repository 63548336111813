@use "../variables" as *;

.socialMedia {
    display: flex;
    margin-top: 30px;

    li {
        display: inline-block;
        opacity: 0.75;
        transition: 0.3s ease-in-out;
        min-height: 35px;
        min-width: 35px;
        position: relative;

        &:not(:first-child) {
            margin-left: 20px;
        }

        &:hover {
            opacity: 1;
        }

        img {
            height: 64px;
            width: 64px;
        }

        a {
            display: block;
            height: 100%;
        }

        .icon {
            height: 35px;
            width: 35px;
            fill: #46bfe8;
            border-radius: 50%;
            background: $color-white;
        }
    }
}
