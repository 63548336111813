@use "../variables" as *;
@use "../mixins/breakpoint" as *;

@use "subNavLink";

.navLink {
    padding: 0;
    margin: 0 30px;
    flex-direction: column;
    align-items: center;
    display: flex;

    @include breakpoint(lg) {
        flex-direction: row;
        margin: 0 0 0 25px;
        justify-content: flex-end;
    }
}

.navLink > li {
    list-style: none;
    padding: 0;
    position: relative;

    @include breakpoint(lg) {
        position: static;
    }
}

.navLink__expandIcon,
.navLink__collapseIcon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    height: 50px;
    padding: 1em 2em;
    display: none;
    opacity: 0;
    transition: all 0.5s ease;
}

.navLink__subnav {
    animation: fadeIn 0.2s linear forwards;
    display: none;
    margin-top: -2px;
    opacity: 0;
    position: absolute;
    z-index: 5;
    min-width: 100%;
    padding: 1.5em 0 2em 0;
    left: 0;
    transition: all 0.5s;
    background: $color-jabil-dark-blue;
}

.nav__mobileOverlay {
    .navLink__trigger {
        width: 50px;
        height: 50px;
        padding: 0;
        margin-right: 15px;
        display: flex;
        justify-content: center;

        img {
            padding: 0;
            width: 18px;
            right: auto;
        }
    }

    .navLink__expandIcon,
    .navLink__collapseIcon {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        height: 50px;
        padding: 1em 2em;
        transition: all 0.5s ease;
        display: flex;
        justify-content: center;
        opacity: 1;
    }

    .navLink__item {
        display: flex;

        .navLink__trigger--collapse {
            display: none;
            opacity: 0;
        }

        &--active {
            .navLink__trigger--expand {
                display: none;
                opacity: 0;
            }

            .navLink__trigger--collapse {
                display: flex;
                opacity: 1;
            }
        }
    }

    .navLink__subnav {
        margin-top: 45px;
    }
}

.nav__globe {
    .navLink__expandIcon {
        display: block;
    }

    &.navLink__item--active {
        .navLink__expandIcon {
            display: none;
        }

        .navLink__collapseIcon {
            display: block;
            opacity: 1;
        }
    }
}

.magnolia-editmode .mgnlEditorBar ~ .navLink__subnav {
    display: block;
    position: relative;
    background: none;
    max-height: 100%;
}

.navLink li:focus-within > .navLink__subnav,
.navLink li .navLink__subnav:focus {
    @include breakpoint(lg) {
        opacity: 1;
        display: block;
    }
}

// this needs to be separate from the chain above
// because some browsers do not have :focus or
// :focus-within, resulting in the entire chain
// not being applied
.navLink li .navLink__subnav:hover,
.navLink li:hover > .navLink__subnav {
    @include breakpoint(lg) {
        opacity: 1;
        display: block;
    }
}

.navLink__title {
    color: $color-white;
    border-bottom: 2px solid transparent;
    font-size: 1em;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    transition: 0.2s;
    transform: scale(1);
    display: block;

    &:hover {
        color: $color-white;
    }

    @include breakpoint(lg) {
        padding: 24px 30px;
    }
}

.navLink__submenus {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;

    @include breakpoint(lg) {
        justify-content: center;
        flex-wrap: nowrap;
        padding: 0 2em;
    }

    .ulClassListArea {
        padding: 0 20px;
        margin: 0;
        flex-basis: 100%;

        @include breakpoint(lg) {
            max-width: 30%;
        }

        @include breakpoint(xl) {
            max-width: 20%;
        }

        &:last-child {
            margin-right: 0;
        }

        a:hover {
            color: $color-white;
        }
    }

    .ulClassListAreaColumns {
        @include breakpoint(lg) {
            display: flex;
        }
    }

    .ulClassListArea__titleWrap {
        @include breakpoint(lg) {
            min-height: 2rem;
            margin-bottom: .5rem;
        }
    }

    .ulClassListArea__title {
        opacity: 1;
        line-height: 2;
    }

    .ulClassListArea__title:after {
        content: "";
        display: block;
        border-bottom: 4px solid $color-jabil-light-blue;
        width: 100%;
        max-width: 215px;
    }

    a.ulClassListArea__title {
        transition: all 0.2s ease-in;

        &:hover {
            color: $color-jabil-light-blue;
        }
    }
}

.navLink li:hover .navLink__title {
    background-color: $color-jabil-dark-blue; 
}

.magnolia-editmode {
    .navLink {
        margin: 0;
    }

    .navLink__submenus {
        display: block;
    }

    .mgnlEditorBar ~ .navLink {
        display: block;
        width: 100%;
    }

    .mgnlEditorBar ~ .navLink li:hover .navLink__title {
        background: none;
    }
}
