@use "../variables" as *;

.title {
    font-family: $font-lato;
    padding: 0 0 10px;
    margin: 0;
    margin-bottom: 10px;
    color: $color-black;
}

.title--centered {
    text-align: center;
}

@each $h, $fontSize in $richTextHeaderSizes {
    .#{$h},
    .title--#{$h} {
        font-size: $fontSize;
    }
}
