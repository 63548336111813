@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.linkListSplit {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -30px;

    @include breakpoint(md-lg) {
        margin-top: 0;
    }

    @media (min-width: 1300px) {
        margin-top: 0;
    }

    @include breakpoint(lg) {
        margin-top: 50%;
        transform: translateY(-50%);
    }
}

.linkListSplit--halfMargin {
    @include breakpoint(lg) {
        margin-top: 25%;
        transform: translateY(-25%);
    }
}

.linkListSplit__link {
    width: 100%;
    color: $color-white-transparent-80;
    text-decoration: none;
    margin-top: 20px;

    &:hover {
        color: $color-white;
    }

    // @media (min-width: 1300px) {
    //     // width: 50%;
    //
    //     &:nth-child(even) {
    //         text-align: right;
    //     }
    // }
}
