@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.footerContents__column {
    .image {
        margin-bottom: 0;
        margin-top: 0;
    }

    .textBlock__title,
    .pardotForm__title {
        font-size: 1.25rem;
        font-weight: 700;
        line-height: normal;
    }

    .contactNumbers__label,
    .pardotForm__field label {
        font-size: 1rem;
        color: $color-white-transparent-75;
    }

    .contactNumbers {
        margin-top: 20px;

        @include breakpoint(lg) {
            display: flex;
            flex-wrap: wrap;
        }

        li {
            @include breakpoint(lg) {
                &:first-child {
                    flex-basis: 40%;
                }

                &:nth-child(2) {
                    flex-basis: 60%;
                }

                &:nth-child(3) {
                    flex-basis: 100%;
                    margin-top: 25px;
                }
            }
        }

        span {
            display: block;
        }

        a {
            display: inline-block;
            border: 1px solid $color-white;
            font-size: 0.875rem;
            font-weight: 700;
            margin-top: 10px;
            padding: 12px 5px;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            width: 250px;
        }
    }

    .contactNumbers__label {
        + span {
            font-size: 1.25rem;
            font-weight: 700;
        }
    }

    .button {
        margin-top: 25px;
        width: 100%;
    }

    .pardotForm {
        padding: 0;
    }

    .pardotForm__title {
        margin: 0;
        padding-bottom: 0;

        &:after {
            display: none;
        }
    }

    .pardotForm__description {
        margin-bottom: 0;
    }

    .pardotForm__form {
        margin-top: 30px;
    }

    .pardotForm__field {
        float: none;
        padding: 0;
        width: 100%;

        &:not(:first-child) {
            margin-top: 10px;
        }

        input[type="email"],
        input[type="text"] {
            width: 100%;
        }
    }

    .pardotForm__submit {
        padding: 0;

        .button {
            &:hover {
                cursor: pointer;
                background-position: 0;
            }
        }
    }
}
