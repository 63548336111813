@use "../reset";
@use "../shared";

@use "../modules/wrap";
@use "../modules/spinner";
@use "../modules/pardotForm";
@use "../modules/linkListSplit";
@use "../modules/footerColPardot";
@use "../modules/twitterFeed";
@use "../modules/socialMedia";
@use "../modules/gallery";
@use "../modules/buttonArea";
@use "../modules/button";
@use "../modules/nav";
@use "../modules/footer";
@use "../modules/scrollAnchor";

// header .nav {
//   border-top: 13px solid #46bfe8;
//   border-bottom: 6px solid #fff;
// }

.pageHero {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -10%;
    height: 100%;
    width: 65%;
    background: #002b49;
    transform: skew(160deg);
    background-clip: content-box;
    z-index: 2;
  }
}

@media only screen and (max-width: 900px) {
  .pageHero:before {
    display: none;
  }
}

.casestudyDetail .pageHero, .blogMain .pageHero, .blogPostMain .pageHero, .sustainabilityPage .pageHero {
  &::before {
    display: none;
  }
}

.colorTheme--green .pageHero::before,
.colorTheme--orange .pageHero::before,
.colorTheme--lite-green .pageHero::before,
.colorTheme--blue .pageHero::before {
    background-color: #002b49;
}

.page__wrap {
  margin: 0;
  width: 100%;
  max-width: none;
  background: #fff;
}

.pageHero__subtitle > h2 {
  font-size: 1.3em;

}

.richText {
  font-size: .9em;
  p {
    font-size: .9em;
  }
}

.richText li {
  font-size: .9em;
}

.ctaBand:not(.lazyload) {
  background: none!important;
}

.ctaBand,
.ctaBand__bg:not(.lazyload) {
  background: #e6e7e8!important;
}

.ctaBand:after,
.ctaBand:before,
.colorDarken:after,
.colorTint:after {
  display: none;
}


.ctaList {
  > li {
    width: auto;
    flex: 1 1 33%;
  }
  a {
    display: flex;
    flex-wrap: wrap;
  }
  .cta__image {
    width: 100%;
  }
  .cta__content {
    padding: 1.3rem;
  }
  .button {
    color: #002b49;
    border-color: #545454;
  }
  .cta__description
  {
    color: #070707;
    border-color: #545454;
  }
  .cta__title {
    border-color: #545454;
  }
  .button {
    &:hover {
      color: #fff;
    }
  }
}

//   justify-content: flex-start;
//   flex-wrap: wrap;
//
// .page__content {
//   padding: 2rem 3% 0 0;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: flex-start;
// }
// .page__container.wrap {
//   max-width: none;
//   width: 100% !important;
//   padding: 0 !important;
//   margin: 0;
//   justify-content: center;
//   background: #fff;
// }
// .page__content.page__wrap {
//   padding: 0 3.5%;
// }
// .page__sidebar {
//   padding-top: 2rem;
// }
// .boxedTexts {
//   flex: 1 1 100%;
//   // min-height: 200px
//   li {
//     font-size: .9em;
//   }
// }



// .formPage .page__content {
//   background: none;
// }
//
// .page--backgroundWhite {
//   background: #fff;
// }
//
// .locationDetailMain__address {
//   h3 {
//     font-size: 1em;
//   }
//   p {
//     font-size: .9em;
//   }
// }
// .wrap--sm-full {
//   padding: 0;
//   margin: 0;
//   width: 100%;
//   max-width: none;
//   .page__content {
//     padding: 3rem 5%;
//     justify-content: center;
//     display: flex;
//   }
//   .richText {
//     max-width: 83.5rem;
//   }
// }
// .sectionSubnav {
//   margin: 0;
//   padding: 0;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   max-width: none;
//   width: 100%;
//   background: #003865;
// }
// .sectionSubnav__wrap {
//   max-width: 1400px;
//   width: 100%;
// }
// .card.ltr {
//   width: 100%;
// }
// .boxedTexts--lightGray,
// .boxedTexts--zebra:nth-child(odd) {
//   background-color: #e5e5e5;
//   h3,
//   li,
//   span {
//     color: #545454;
//     &::before {
//       background: #545454;
//     }
//   }
// }
//
// .colorTheme--blue .sectionSubnav__wrap {
//   background: none!important;
// }
//
// .colorTheme--blue .sectionSubnav__topMenuList,
// .colorTheme--blue .sectionSubnav__subNav .sectionSubnav__menuLink {
//   background: none;
// }
//
// .page__industriesRelated .ctaBand,
// .ctaBand.theme--dark {
//   background: #e6e7e8;
// }
//

//
// .ctaBand__bg.colorTint:after {
//   background: none!important;
// }
//
// .ctaBand .ctaBand__title {
//   color: #000;
// }
//
// .socialMedia li .icon {
//   border-radius: 50%;
//   fill: #46bfe8;
//   background: #fff;
// }
//
// .footerContents__column .pardotForm__form {
//   align-items: flex-start;
// }
//
// .footerContents__column .pardotForm__field,
// .footerContents__column .pardotForm__submit {
//   width: auto;
//   flex: 1 1 49%;
//   margin: 0!important;
// }
//
// .pardotForm--transparent.pardotForm input {
//   background: #003764;
// }
//
// @media (min-width: 1024px) {
//   .sectionSubnav__mobileWrap:after {
//     clip-path: none;
//     border: none!important;
//   }
// }
//
// @media (min-width: 1366px) {
//   .sectionSubnav__wrap {
//     padding: 0 2rem;
//   }
//
//   .pageHero__title > h2 {
//     font-size: 1em;
//   }
//

//
//   .page--hasSidebar .page__container {
//     flex-wrap: wrap;
//     justify-content: center;
//   }
//
//   .articleHeadline,
//   .ctaBand  {
//     flex: 1 1 100%;
//   }
//
//   .page__content.page__wrap {
//     padding: 0 3.5%;
//   }
//
// }
//
//
// @media (min-width: 1600px) {
//   .page__content.page__wrap {
//     max-width: 50rem;
//   }
// };
