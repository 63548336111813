@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.subNavLink {
    display: block;
    margin: 0;
    text-align: left;
    flex-grow: 1;

    @include breakpoint(md) {
        max-width: 326px;
    }
}

.subNavLink__item,
.ulClassListArea__title {
    padding: 0;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: none;
    text-decoration: none;
    color: $color-white;
    opacity: 0.5;
    border: 1px solid transparent;
    display: block;
    margin-bottom: 0;
    transition: ease-in 0.2s;

    a {
        color: $color-white;
        text-decoration: none;
        padding: 0.5em 1em;
        display: block;
    }
}

.subNavLink__item {
    cursor: pointer;
    color: $color-white;
    max-width: 217px;
}

.subNavLink__title--active {
    opacity: 1;
    border-color: $color-white;
}

.subNavLink__item:hover,
.subNavLink__item:hover a {
    opacity: 1;
    border-color: $color-white;
    color: $color-white;
}
