@use "../variables" as var;

@mixin breakpoint($class) {
    @if $class == sm {
        @media (max-width: var.$width-tablet-min - 1) {
            @content;
        }
    } @else if $class == md {
        @media (min-width: var.$width-tablet-min) {
            @content;
        }
    } @else if $class == md-lg {
        @media (min-width: var.$width-large-tablet-min) {
            @content;
        }
    } @else if $class == lg {
        @media (min-width: var.$width-desktop-min) {
            @content;
        }
    } @else if $class == xl {
        @media (min-width: var.$width-standard-screen-min) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg-md, lg, xl";
    }
}
