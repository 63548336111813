@use "../variables" as *;
@use "../mixins/breakpoint" as *;
@import "~photoswipe/src/css/default-skin/default-skin";
@import "~photoswipe/src/css/main";

.gallery {
    overflow: hidden;
    position: relative;
    margin: 2em 0;
    padding: 0;

    @include breakpoint(md) {
        margin: 2em auto;
    }
}

.gallery__titleContainer {
    display: flex;
    justify-content: center;
    padding-top: 1em;
    margin-top: 1em;

    h3 {
        margin: 0;
    }
}

.gallery__title {
    color: $color-white;
    font-size: 1.5rem;
    font-family: Roboto, sans-serif;
    z-index: 5;

    &.gallery__title--dark {
        color: $color-black;
    }
}

.gallery__container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.galleryImage {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center center;
}

.smartImage {
    max-width: 100%;
    min-height: 175px;
    height: auto;
    object-fit: cover;
    object-position: center center;
}

.gallery__imageTitle {
    text-align: center;

    h4 {
        margin: 0;
        font-size: 0.8rem;
        color: $color-white-transparent-80;
    }

    &.gallery__imageTitle--dark {
        h4 {
            color: $color-black;
        }
    }
}

.gallery__imageContainer {
    position: relative;
}

.gallery__imageOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.4s ease;
    background-color: $color-black-transparent-50;
    pointer-events: none;
}

.gallery__imageContainer:hover .gallery__imageOverlay {
    opacity: 1;
}

.gallery__caption {
    color: $color-white-transparent-80;
    font-size: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.gallery__items {
    overflow: visible;

    //ie11 support
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .image {
        margin-bottom: 3px;
    }

    .imagePad {
        &.image {
            margin-bottom: 25px;
        }
    }
}

//items per row
.imageRow--two {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-gap: 10px 20px;
    justify-content: center;

    @include breakpoint(md) {
        display: grid;
        grid-template-columns: repeat(2, 45%);
        grid-gap: 20px;
        justify-content: center;
    }

    //ie11 support
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .image {
            width: 50%;
            padding: 0 1em;
        }
    }
}

.imageRow--three {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-gap: 10px 20px;
    justify-content: center;

    @include breakpoint(md) {
        grid-template-columns: repeat(2, 50%);
    }

    @include breakpoint(lg) {
        display: grid;
        grid-template-columns: repeat(3, 34%);
        grid-gap: 20px;
        justify-content: center;
    }

    //ie11 support
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .image {
            width: 33.33%;
            padding: 0 1em;
        }
    }
}

.imageRow--four {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-gap: 10px 20px;
    justify-content: center;

    @include breakpoint(md) {
        grid-template-columns: repeat(2, 50%);
    }

    @include breakpoint(lg) {
        grid-template-columns: repeat(4, 23.5%);
    }

    .smartImage {
        min-height: 210px;
    }

    //ie11 support
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .image {
            width: 25%;
            padding: 0 1em;
        }
    }
}

.imageRow--five {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-gap: 10px 20px;
    justify-content: center;

    @include breakpoint(md) {
        grid-template-columns: repeat(2, 50%);
    }

    @include breakpoint(lg) {
        display: grid;
        grid-template-columns: repeat(5, 19.5%);
        grid-gap: 10px 20px;
        justify-content: center;
    }

    //ie11 support
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .image {
            width: 20%;
            padding: 0 1em;
        }
    }
}

//override pswp styles
.pswp__caption__center {
    text-align: center !important;
    padding-bottom: 2em;
}
