@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.nav__stock {
    display: block;
    list-style: none;
    text-align: center;
    color: $color-grey-bc;
    font-size: 0.9rem;
    padding: 15px 25px;
    margin-top: map-get($spacing, 2);
    height: auto;

    @include breakpoint(lg) {
        margin: 0 1px 0 7px;
        padding: 0;
        font-size: 0.7rem;
        height: 45px;
        display: flex;
        align-items: center;
    }

    @include breakpoint(xl) {
        margin: 0 1px 0 14px;
        font-size: 0.8rem;
    }

    .stockUp {
        color: $color-stock-pos;
    }

    .stockDown {
        color: $color-stock-neg;
    }
}

.nav__stockPos {
    margin-left: 4px;
}
