@use "../variables" as *;
@use "../keyframes" as *;
@use "../mixins/breakpoint" as *;

.spinner {
    border: 0.7em solid $color-grey-de;
    border-top: 0.7em solid $color-jabil-blue;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    width: 3em;
    height: 3em;
    margin: 6em auto;

    @include breakpoint(lg) {
        margin: 10em auto;
        width: 4em;
        height: 4em;
        border-width: 0.9em;
    }
}
