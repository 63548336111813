@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.navLangWrap {
    position: absolute;
    background-color: transparent;
    display: none;
    top: 52px;
    z-index: 6;
    left: 0;
    margin-top: -2px;
    width: 100%;
    animation: fadeIn 0.2s linear forwards;

    @include breakpoint(lg) {
        background-color: $color-black-transparent-70;
        display: block;
        transform: translateX(-40%);
        margin-top: -2px;
        right: auto;
        left: auto;
        top: 45px;
        width: auto;
    }
}

.navLangDropdown {
    animation: fadeIn 0.2s linear forwards;
    min-width: 100%;
    padding: 1.5em 1em 2em 0;
    left: 0;
    transition: all 0.5s;
    text-align: left;
    opacity: 0;
    display: none;

    li {
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
    }

    .navLink__title:hover {
        color: $color-jabil-blue;
    }
}

.navLangWrap__mobile {
    display: block;

    @include breakpoint(lg) {
        display: none;
    }

    .navLangDropdown__mobile {
        animation: fadeIn 0.3s linear forwards;
        min-width: 100%;
        left: 0;
        transition: all 0.5s;
        text-align: left;
        opacity: 0;
        display: none;

        li {
            border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
        }

        .navLink__title:hover {
            color: $color-jabil-blue;
        }

        &.navLangDropdownMobile--open {
            display: block;
            opacity: 1;
        }
    }
}

.nav__globe {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px 25px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);

    @include breakpoint(lg) {
        border-bottom: none;
        padding: 12px 24px;
        position: static;

        &:hover {
            svg {
                fill: $color-jabil-blue;
            }

            .navLangDropdown {
                opacity: 1;
                display: block;
            }
        }
    }

    svg {
        fill: $color-white;
        width: 20px;
        height: 20px;
        margin-right: 22px;

        @include breakpoint(lg) {
            right: auto;
            top: auto;
            position: relative;
            margin-right: 0;
        }
    }

    .navLink__expandIcon {
        display: block;
        opacity: 1;
        @include breakpoint(lg) {
            opacity: 0;
            display: none;
        }
    }

    &.navLink__item--active {
        .navLangDropdown__mobile {
            display: block;
            opacity: 1;
        }
    }
}

/* stylelint-disable */
.nav__globe--admin {
    position: relative;
    .navLangWrap {
        position: relative;
        width: 100%;
        display: block;
        transform: translateX(0);
    }

    .navLangDropdown {
        opacity: 1;
        display: block;
    }

    .navLangWrap__mobile {
        display: none;

        .navLangDropdown__mobile {
            display: none;
        }
    }
}
/* stylelint-enable */
