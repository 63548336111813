@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

/* stylelint-disable */
.richText {
    font-size: 1.125em;
    position: relative;

    * {
        line-height: 1.5;
    }

    @each $h, $fontSize in $richTextHeaderSizes {
        #{$h} {
            font-size: $fontSize;
            padding-top: 1rem;
            padding-bottom: 0.5rem;
        }
    }

    p {
        margin-bottom: 1em;
    }

    li {
        margin-bottom: 0.3em;
    }

    ul,
    ol {
        margin-bottom: 1.25em;
    }

    ul {
        margin-top: 0.15em;
    }

    ol {
        margin-top: 0.25em;
    }

    li ul,
    li ol {
        margin-bottom: 0.3em;
    }

    .richText__bgImage {
        background-size: cover;
        object-fit: cover;
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: -1;
        background-repeat: no-repeat;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: " ";
            z-index: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.3) 33%);
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: " ";
            z-index: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.3) 10%);
        }
    }
}

.richText--darkBullets {
    li {
        color: $color-black;
    }
}

.richText--colorBox {
    padding: 30px;
    margin-bottom: 1em;

    @media screen and (min-width: $width-desktop-min) {
        margin-bottom: 42px;
    }

    * {
        color: $color-white;
    }

    p {
        margin-bottom: 0;

        &:not(:first-child) {
            margin-top: 1em;
        }
    }
}

.richText--removeTopPad,
.richText__title--removeTopPad h3 {
    padding-top: 0;
}

@include forColors(
        $optOut: (
            "orange"
        )
    )
    using ($color, $value) {
    .colorTheme--#{$color} {
        .richText--text {
            @each $h, $size in $richTextHeaderSizes {
                @if isColorDark($value) {
                    #{$h} {
                        color: $value;
                    }
                }
            }

            th {
                background: $value;
                color: #fff;
            }
        }
    }

    .richText--colorBox.richText--theme-#{$color} {
        background-color: $value;
    }
}

@each $width, $value in $imageSize {
    .richText__image#{$width} {
        width: $value;

        @media screen and (max-width: $width-tablet-min) {
            width: 100%;
        }
    }
}

.richText__imageleft,
.richText__imageright {
    display: block;
    float: none;

    @media screen and (max-width: $width-tablet-min) {
        margin-bottom: 1em;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.richText__imageleft {
    @media screen and (min-width: $width-tablet-min) {
        float: left;
        padding-bottom: 15px;
        padding-right: 20px;
    }
}

.richText__imageright {
    @media screen and (min-width: $width-tablet-min) {
        float: right;
        padding-bottom: 15px;
        padding-left: 20px;
    }
}

.richText__imageDescription {
    font-size: 0.75rem;
}

//offset where the anchor jumps to
.richText--scrollTo {
    padding-top: 60px !important; 
    margin-top: -60px;
}

//rich text table styles
div.richText table {
    overflow-x: auto;
    overflow-y: auto;
    min-width: 100%;
}

div.richText table,
div.richText tbody {
    text-align: left;
    border-collapse: collapse;
    border: 0;
}

div.richText tr {
    width: 100%;
}

div.richText tr:nth-of-type(odd) {
    background: $color-grey-dd;
}

div.richText table.cs_info tr {
    background-color: transparent;
}

div.richText table.cs_info td {
    padding: 0 5px 5px 0;
    font-size: 16px;
}

div.richText table.cs_info tr td:first-of-type {
    color: #1866B4;
    font-weight: 600;
}

div.richText .richText__table--noZebra {
    tr:nth-of-type(odd) {
        background: unset;

        _:-ms-input-placeholder,
        :root & {
            background: transparent;
        }
    }
}

div.richText .richText__table--narrowFirstCol {
    tr {
        > td:first-child {
            padding-left: 0;
            padding-right: 0;
        }
    }
}


.richText--titleHr .richText__title,
div.richText .richText__table--underlineHeadings {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        position: relative;
        padding-bottom: 20px;

        &:after {
            content: " ";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 250px;
            border-bottom-width: 3px;
            border-bottom-style: solid;
            border-bottom-color: $color-jabil-light-blue;

            @media screen and (min-width: $width-tablet-min) {
                border-bottom-width: 5px;
            }
        }
    }
}

div.richText caption {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
    font-size: 1.2rem;
}

div.richText th,
div.richText td {
    padding: 0.3em;
    border: 0 solid #fff;

    > * {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
    }
}

thead tr:last-child {
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    position: relative;
}

div.richText th {
    text-align: left;
    word-wrap: break-word;
}

.richText--blockLine {
    border-left: 3px solid $color-jabil-blue;
    padding: 30px;

    @include breakpoint(xl) {
        margin: 2rem 21rem;
    }
}

@include forColors() using ($color, $value) {
    .colorTheme--#{$color} {
        .richText--titleHr .richText__title,
        div.richText .richText__table--underlineHeadings {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                &:after {
                    border-bottom-color: $value;
                }
            }
        }
    }
}

.richText--darkTitle {
    .richText__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $color-black;
        }
    }
}

.page__industriesRelated {
    .richText {
        a {
            &:hover {
                opacity: 0.85;

                span {
                    @extend a;
                }
            }
        }
    }
}

table.richText__addIconLine {
    position: relative;

    &::before {
        position: absolute;
        border-top: 5px solid #fff;
        height: 5px;
        width: 99%;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        content: "";
        z-index: 1;
    }

    img {
        position: relative;
        z-index: 2;
    }

    tbody {
        tr:nth-of-type(odd) {
            background: transparent;
        }
    }

    @media screen and (max-width: $width-small-tablet-max) {
        &::before {
            display: none;
        }
    }
}

.richText__addIconLine--noLIne {
    &::before {
        display: none;
    }
}

.richText--squareBullets {
    ul {
        list-style-type: square;
    }
}

.richText__title--removeBottomPad h3 {
    padding-bottom: 0;
}

.richText__title--removeBottomMargin h3 {
    margin-bottom: 0;
}
