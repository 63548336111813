@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.ctaListItem--targetUnpublished {
    display: none;
}

.magnolia-editmode .ctaListItem--targetUnpublished {
    display: list-item;
    &:before {
        content: "WARNING: TARGET UNPUBLISHED ⇣";
        color: red;
        font-size: 1.25rem;
    }
}

.cta {
    display: flex;
    text-decoration: none;
    justify-content: space-between;
    color: $color-black;
    width: 100%;
    padding: 0 0 0px;
    margin-top: 0px;
    align-items: center;

    .button {
        display: initial;
        align-self: flex-start;
        width: 100%;
    }
}

.cta__imageWrapper {
    display: block;
    width: 100%;
    position: relative;
    // maintain a ~13:5 image ratio
    padding-top: 38.46%;
}

.cta__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 2;
    width: 100%;
}

.cta__image img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
}

.cta__title {
    color: $color-jabil-dark-blue;
    padding: 20px 0 0 0;
    font-family: $font-lato;
    font-weight: 700;
    font-size: map-get($richTextHeaderSizes, "h5");

    &:hover {
        color: $color-grey-b1;
    }

    &.titleHr {
        padding-bottom: 20px;
    }
}

.cta__description {
    margin-bottom: 40px;
    color: $color-grey-cc;
    font-size: 1rem;
    font-weight: 300;

    &--noDesc {
        display: none;
    }
}

.cta__justifyBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cta__justifyAround {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 0;

    @include breakpoint(lg) {
        padding: 30px 0;
    }
}

.cta__icon svg {
    height: 35px;
    width: 35px;
}

.cta--horizontal,
.cta--vertical {
    flex-direction: column;
    height: 100%;

    .cta__title {
        margin-bottom: 9px;
    }

    @include breakpoint(sm) {
        .cta__image {
            overflow: hidden;
            height: 0;
            padding-bottom: 39%;
        }
    }
}

.cta--horizontal {
    @include breakpoint(md) {
        flex-direction: row-reverse;

        .cta__image {
            width: 33.33%;
        }

        .cta__content {
            width: 63%;
        }

        .cta__title {
            padding: 0;
            margin-top: 0;
        }

        .cta__description {
            margin-bottom: 30px;
            margin-right: 30px;
        }
    }
}

.cta--overlay {
    display: block;
    position: relative;
    padding: 30px 16px 0;
    height: 100%;

    @include breakpoint(sm) {
        padding: 30px 16px 16px;
    }

    .cta__image {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        filter: blur(2px);
    }

    .cta__image:after {
        background: $color-black-transparent-70;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .cta__description {
        position: relative;
        z-index: 2;
        margin-bottom: 0;
        min-height: 135px;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 60px;
        min-width: auto;
    }
}

.cta--text {
    align-items: center;
    .cta__icon {
        margin-right: map-get($spacing, 2);
    }
}

.cta--sm {
    .cta__image {
        width: 25%;
    }

    .cta__title {
        font-size: 0.9rem;
    }

    .cta__icons {
        width: 13%;

        @include breakpoint(md) {
            width: 20%;
        }

        @include breakpoint(lg) {
            width: 11%;
        }
    }
}

.ctaList--hideDescriptions {
    .cta__description {
        display: none;
    }
}

.ctaList--hideDates {
    .blogListing__blogDate {
        display: none;
    }
}

.ctaList--hideCats {
    .listItem__categoryList {
        display: none;
    }
}
