@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

.share {
    margin-bottom: 2em;

    @include breakpoint(md) {
        margin: 0 2em 0 0;
    }

    .homeIcon {
        vertical-align: middle;
        border-radius: 5px;
        background-color: $color-jabil-blue;
        color: $color-white;
        max-width: 2.3em;
        width: 175px;
        height: 2.3em;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        transition: max-width 0.25s ease-in;

        @media (max-width: $width-tablet-max) {
            display: none;
        }

        .homeIcon__icon {
            opacity: 0.8;
            width: 2.3em;
            height: 100%;
            display: inline;
        }

        .homeIcon__title {
            width: 175px;
            font-size: 0.75em;
            text-transform: uppercase;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            margin: 0;
            padding: 0.75em 0.25em;
            white-space: nowrap;
        }

        &:hover {
            max-width: 175px;

            .homeIcon__title {
                opacity: 1;
            }
        }
    }
}

.share__network {
    display: inline-block;
    margin: 0 0.5em 0 0;

    & .icon {
        width: 2.3em;
        height: 2.3em;
        opacity: 0.5;
        vertical-align: middle;
        transition: all ease-in-out 0.5s;
    }

    &:hover {
        & > .icon {
            opacity: 1;
        }
    }
}

.share--light {
    .share__network svg {
        fill: $color-white;
    }
}

@include forColors() using ($color, $value) {
    .colorTheme--#{$color} {
        .homeIcon {
            background-color: $value;
        }
    }
}
