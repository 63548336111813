@use "../variables" as *;
@use "../keyframes" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

.pardotForm {
    padding: 2em 1.7em;
    margin-bottom: 1em;
    color: $color-white;
    position: relative;
}

.pardotForm.wrap {
    padding: 2em 1.7em;
}

.pardotForm--subscription {
    label,
    input {
        display: block;
        font-size: 1rem;
    }

    label {
        margin-bottom: 10px;
    }

    input {
        padding: 0.3em 0.5em;
        width: 100%;
        background: rgba(250, 250, 250, 0.1);
        border: 1px solid $color-white;
    }
}

.pardotForm__form {
    display: none;
}
.pardorForm__form--visible {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.pardotForm__submit {
    width: 100%;
    padding: 1.5em 0 0;

    button {
        border-width: 2px;
        border-color: $color-white;
        color: $color-white;

        @include breakpoint(sm) {
            display: block;
            width: 100%;
        }
    }
}

.pardotForm__form--submitted {
    .pardotForm__submit {
        cursor: wait;
    }
}

.pardotForm__field {
    width: 100%;
    padding: 0.5em 0;

    @media screen and (min-width: $width-desktop-min) {
        width: 50%;
    }
}

.pardotForm__field:nth-child(odd) {
    @include breakpoint(lg) {
        padding-right: 1%;
    }
}

.pardotForm__field:nth-child(even) {
    @include breakpoint(lg) {
        padding-left: 1%;
    }
}

.pardotForm__title {
    font-size: 1.5rem;
    font-weight: bold;
    position: relative;
    margin: 0 0 0.5em;
    padding: 0 0 0.8em;
}

.pardotForm__title:after {
    content: "";
    border-bottom-width: 5px;
    border-bottom-style: solid;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 25%;
    min-width: 50px;
}

.pardotForm_centerLine {
    width: 100%;
    border-top: 4px solid $color-white;
    max-width: 250px;
    margin: 0 auto;
    padding-bottom: 1.7em;
}

.pardotForm__description {
    font-size: 1rem;
    margin-bottom: 1em;
}

.pardotForm__wrap {
    opacity: 0;
    min-height: 450px;

    &.pardotForm--centerTitle {
        .pardotForm__title {
            margin: 0;
            padding: 0 0 0.3em;
            font-size: 1.7rem;
            text-align: center;
        }

        .pardotForm__title:after {
            display: none;
        }
    }

    &.pardotForm--noBottomMargin {
        margin: 5em auto 0;
    }

    & > h3,
    > div {
        display: none;
    }
}

.pardotForm__wrap--fadeIn {
    animation: fadeIn 1s linear forwards;
    min-height: 0;

    > h3,
    > div {
        display: block;
    }
}

.pardotForm__spinner {
    border: 0.7em solid $color-grey-de;
    border-top: 0.7em solid $color-jabil-blue;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    width: 3em;
    height: 3em;
    margin: 6em auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @include breakpoint(lg) {
        margin: 10em auto;
        width: 4em;
        height: 4em;
        border-width: 0.9em;
    }
}

.pardotForm__spinner--fadeOut {
    animation: spin 1s linear infinite, fadeOut 1s linear forwards;
}

.pardotForm--transparent {
    input::placeholder {
        color: rgba(250, 250, 250, 0.8);
    }
}

@include forColors(
        $optOut: (
            "orange",
            "transparentBlack"
        )
    )
    using ($color, $value) {
    $formHighlightColor: $color-black;
    @if isColorDark($value) {
        $formHighlightColor: $color-white;
    }

    .pardotForm--#{$color}.pardotForm,
    .colorTheme--#{$color} .pardotForm {
        background-color: $value;

        .pardotForm__spinner {
            border-top-color: $value;
        }

        &,
        input {
            color: $formHighlightColor;
        }

        input,
        .pardotForm__title:after,
        .button {
            border-color: $formHighlightColor;
        }

        input {
            background: rgba($formHighlightColor, 0.1);

            &::placeholder {
                color: rgba($formHighlightColor, 0.7);
            }
        }
    }

    .colorTheme--#{$color} {
        .pardotForm--center.pardotForm {
            .pardotForm__innerWrap {
                background: $value;
                background: linear-gradient(180deg, rgba($value, 1) 20%, darken($value, 25%) 100%);
            }
        }

        .pardotForm__form--submitted {
            .button--hollow {
                opacity: 0.9 !important;
                background: #acacac !important;
                border-color: #acacac !important;
                color: #ccc !important;
                pointer-events: none;
            }
        }
    }
}

.pardotForm--transparentBlack.pardotForm {
    color: $color-white;
    background-color: map-get($colorPops, "transparentBlack");

    input {
        background: rgba(250, 250, 250, 0.1);
        border-color: $color-white;
        color: $color-white;

        &::placeholder {
            color: rgba($color-white, 0.7);
        }
    }

    .pardotForm__title:after {
        border-bottom-color: $color-white;
    }

    .button {
        color: $color-white;
        border-color: $color-white;
    }
}

.pardotForm--transparent.pardotForm {
    color: $color-white;
    background-color: transparent;

    input {
        background: rgba(250, 250, 250, 0.1);
        border-color: $color-white;
        color: $color-white;

        &::placeholder {
            color: rgba($color-white, 0.7);
        }
    }

    .pardotForm__title:after {
        border-bottom-color: $color-white;
    }

    .button {
        color: $color-white;
        border-color: $color-white;
        
        &.button--hollow {
            background-color: transparent !important;
        }

        &:hover {
            background-image: linear-gradient(45deg, #000 50%, transparent 0);
        }
    }
}

.pardotForm--transparentBlack.pardotForm .button {
    background-color: transparent !important;
}

.pardotForm--center.pardotForm {
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    padding: 5vw;
    background-color: transparent;

    @include breakpoint(lg) {
        padding: 2rem 10rem;
    }

    .pardotForm__innerWrap,
    .pardotForm__wrap {
        width: 100%;
    }

    .pardotForm__innerWrap {
        display: flex;
        justify-content: center;
        padding: 5rem 1rem;
        box-shadow: 0 0 50px $color-black-transparent-40;

        @include breakpoint(lg) {
            padding: 5rem 8rem;
            max-width: $width-standard-screen-min;
        }
    }
}

.page__sidebar {
    .pardotForm {
        margin-bottom: 2.5rem;
    }
}
