@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

@mixin vCenter {
    .columns__column--vCenterChildren {
        display: flex;
        align-self: center;

        > * {
            flex-grow: 1;
        }
    }
}

.columnsContainer {
    position: relative;

    &.columnsContainer--marginBottom {
        margin-bottom: 42px;
    }

    &.columnsContainer--paddingTop {
        padding-top: 50px;
    }

    &.columnsContainer--paddingBottom {
        padding-bottom: 50px;
    }
}

.columns--hasColumnsSpaces {
    margin-left: -1rem;
    margin-right: -1rem;

    .columns__column {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.columns--addRightArrows {

    .columns__column {
        position: relative;

        &:after {
            position: absolute;
            right: 0;
            top: 25%;
            display: block;
            height: 1em;
            width: 1em;
            color: #fff;
            content: '\203A';
            transform: translateY(-50%);
            font-size: 4em;
            text-align: right;
                @media screen and (max-width: $width-medium-tablet-min) {
                    display: none;
                }
            }

        &:last-of-type:after {
            display: none;
        }


    }
}

.columnsContainer--withRichtextButton {
    .columns__column {
        position: relative;

        .button {
            @media screen and (max-width: $width-standard-screen-max) {
                min-width: auto;
                position: absolute;
                bottom: 0;
            }

            @media screen and (max-width: $width-small-tablet-max) {
                position: relative;
                min-width: 250px;
            }
        }
    }

    .richText {
        @media screen and (max-width: $width-standard-screen-max) {
            position: static;
            margin-bottom: 40px;
        }

        @media screen and (max-width: $width-small-tablet-max) {
            position: relative;
            margin-bottom: 0;
        }
    }
}

.columns {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
        flex-direction: row;
    }

    html & .ctaBand:after {
        background: transparent;
    }

    &.columns--3-up {
        @include breakpoint(md) {
            flex-direction: column;
        }

        @media screen and (min-width: $width-medium-tablet-min) {
            flex-direction: row;
        }
    }

    &.columns--4-up {
        .columns__column {
            padding-top: 0;
            padding-bottom: 0;

            @include breakpoint(md) {
                padding-top: 2em;
                padding-bottom: 2em;
            }

            .richText {
                height: 100%;
            }
        }
    }
    &.columns--5-up {
        .columns__column {
            padding-top: 0;
            padding-bottom: 0;

            @include breakpoint(md) {
                padding-top: 2em;
                padding-bottom: 2em;
                margin: 0 .5em;
            }

            .richText {
                height: 100%;
            }
        }
    }
}

.columns__title {
    font-family: $font-lato;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0 0 10px;
}

/* stylelint-disable */
//TODO: We need to redo the ctaBand component just being used inside the columns component
// In Return we will not have to do these style overrides -- Nate
.theme--dark {
    .columns__title {
        color: $color-white;
    }

    .columns__column {
        .ctaBand {
            &:before {
                background-color: transparent;
            }
        }
    }
}

.columns__column {
    flex: 1 1 0px;

    //TODO: We need to redo the ctaBand component just being used inside the columns component
    // In Return we will not have to do these style overrides -- Nate
    .theme--dark & {
        .ctaBand {
            &:before {
                background-color: transparent;
            }
        }
    }
    .theme--dark {
        .ctaBand {
            &:before {
                background-color: transparent;
            }
        }
    }

    .theme--dark.ctaBand {
        background-color: transparent;
        &::before {
            background-color: transparent;
        }
    }

    .ctaBand__bg.colorTint {
        &:after {
            background-color: transparent;
        }
    }

    .richText--colorBox {
        margin-bottom: 0;
    }

    .button,
    .button--bottomMargin {
        margin-top: auto;
        @include breakpoint(md) {
            max-width: 300px;
        }
    }
    //END -- overrides
}
/* stylelint-enable */

.columns--1-up {
    flex-direction: row;
    @include vCenter;
}

@include breakpoint(md) {
    .columns--2-up {
        .columns__column {
            width: 50%;
        }

        @include vCenter;
    }
}

@media screen and (min-width: $width-medium-tablet-min) {
    .columns--3-up {
        .columns__column {
            width: 33.33333%;
        }
        @include vCenter;
    }
}

.columns--3-up.columns--reducedBreakpoint {
    @include breakpoint(md) {
        flex-direction: row;
        .columns__column {
            width: 33.33%;
        }
        @include vCenter;
    }
}

.columns__bg {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.colorTint {
        &::after {
            background: linear-gradient(-1deg, #000, rgba(0, 0, 0, 0.9) 40%, rgba(0, 0, 0, 0.6));
        }
    }
}

//Columns inside of primary content -- remove wrap padding
.page__content {
    .columns__title {
        color: $color-black;
    }

    .columnsContainer {
        .wrap {
            position: relative;
            z-index: 2;
            padding: 0;
        }
    }
}

@include forColors() using ($color, $value) {
    .columnsContainer--#{$color} {
        background-color: $value;
    }
}

.magnolia-editmode .columns__column--vCenterChildren {
    display: unset;
}

.columnsContainer__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.columnsContainer__background::after {
    content: "";
    background: $color-black-transparent-75;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.columnsContainer--white {
    z-index: 1;

    .columns__bg {
        display: none;
    }

    .richText--colorBox {
        a {
            color: $color-white;
        }

        * {
            color: $color-black;
        }
    }
}
