@use "../mixins/color" as *;

.colorDarken:after,
.colorTint:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.5;
}

@include forColors(
        $optOut: (
            "orange"
        )
    )
    using ($color, $value) {
    .colorTheme--#{$color} .colorTint:after {
        background-color: $value;
    }

    .colorDarken--#{$color}:after {
        background-color: $value;
    }
}

$opacities: (
    "0": 0,
    "10": 0.1,
    "20": 0.2,
    "30": 0.3,
    "40": 0.4,
    "50": 0.5,
    "60": 0.6,
    "70": 0.7,
    "80": 0.8,
    "90": 0.9,
    "100": 1
);

@each $key, $opacity in $opacities {
    .colorDarken--#{$key},
    .colorTint--#{$key} {
        &::after {
            opacity: $opacity;
        }
    }
}
