@use "../variables" as *;
@use "../mixins/color" as *;

.titleHr,
.titleHr--centered {
    position: relative;

    &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 250px;
        max-width: 100%;
        content: " ";
        border-bottom-color: $color-jabil-light-blue;
        border-bottom-style: solid;
        border-bottom-width: 3px;

        @media screen and (min-width: $width-tablet-min) {
            border-bottom-width: 5px;
        }
    }
}

.titleHr--centered {
    margin: 0 auto;

    &:after {
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 300px;
    }
}

.richTextTitle.titleHr--centered {
    margin-bottom: 30px;
}

.richTextTitle.titleHr:after,
.richTextTitle.titleHr--centered:after {
    bottom: 10px;
}

@include forColors() using ($color, $value) {
    .colorTheme--#{$color} .titleHr:after,
    .colorTheme--#{$color} .titleHr--centered:after {
        border-bottom-color: $value;
    }
}
