@use "../variables" as *;
@use "../mixins/breakpoint" as *;

footer {
    background-color: $color-jabil-dark-blue;
    padding-bottom: 40px;
    padding-top: 60px;
    position: relative;
    z-index: 1;

    * {
        color: $color-white;
    }
}

.footerContents {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .wrap {
        padding: 0 1.5rem;
    }

    @include breakpoint(lg) {
        margin: 0 -2rem;
        flex-wrap: nowrap;
    }
}

.footerContents__column {
    border-bottom: 1px solid;
    border-color: #46bfe8;
    padding-bottom: 30px;
    z-index: 1;

    @include breakpoint(md-lg) {
        padding-bottom: 0;
        border-bottom: none;
    }

    &.footer--pushToBack {
        z-index: 0;
    }
}

.footerContents__column1 {
    flex-basis: 100%;
    margin-bottom: 30px;

    .textBlock {
        margin-top: 24px;
    }

    .socialMedia {
        margin-top: 16px;
    }

    .image img {
        margin: 0;
        border: none;
    }

    .svgLogo {
        margin: 0;
        width: 96%;
        max-width: 300px;
    }

    @include breakpoint(md-lg) {
        flex-basis: 50%;
    }

    @include breakpoint(lg) {
        flex-basis: 30%;
        margin-bottom: 0;
    }

    @media (min-width: 1300px) {
        flex-basis: 20%;
    }
}

.footerContents__column2 {
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    margin-bottom: 30px;

    &:after,
    &:before {
        content: "";
        border-color: #46bfe8;
        position: absolute;
        height: 70%;
        top: 3.1rem;
        margin: auto;
        opacity: 0.5;
    }

    @include breakpoint(md-lg) {
        padding-left: 35px;
        padding-right: 35px;
        &:before {
            border-left: 1px solid;
            left: 0;
        }
    }

    @include breakpoint(lg) {
        margin-bottom: 0;
        flex-basis: 20%;
        &:after {
            border-left: 1px solid;
            right: 0;
        }
    }

    @media (min-width: 1300px) {
        flex-basis: 30%;
    }
}

.footerContents__column3 {
    position: relative;
    margin-bottom: 30px;

    @include breakpoint(md-lg) {
        margin-bottom: 0;

        &:after {
            content: "";
            border-color: #46bfe8;
            position: absolute;
            height: 70%;
            top: 3.1rem;
            margin: auto;
            opacity: 0.5;
            border-left: 1px solid;
            right: 0;
        }
    }

    @include breakpoint(lg) {
        flex-basis: 30%;
    }

    &--noBorder:after {
        border: 0 !important;
        border: none !important;
    }
}

.footerContents__column4 {
    @include breakpoint(lg) {
        flex-basis: 25%;
    }

    @media (min-width: 1300px) {
        flex-basis: 20%;
    }
}

.footerContents__column1,
.footerContents__column4 {
    p {
        font-size: 0.75rem;
        line-height: 1.4;
        margin-top: 20px;
    }
}

.footerContents__column1,
.footerContents__column2,
.footerContents__column3,
.footerContents__column4 {
    flex-basis: 100%;

    @include breakpoint(md-lg) {
        flex-basis: 50%;
    }
}

.footerContents--copyright {
    position: relative;
    margin-top: 40px;
    padding-bottom: 40px;
    text-align: right;

    @media screen and (max-width: 1120px) {
        margin-top: 70px;
    }

    p {
        font-size: 0.875rem;
    }
}

.footer--jabilMx {
    flex-basis: 100%;

    .textBlock {
        margin-top: 24px;
    }

    .socialMedia {
        margin-top: 16px;
    }

    .image img {
        margin: 0;
        border: none;
    }

    .svgLogo {
        margin: 0;
        width: 96%;
        max-width: 400px;
    }

    p {
        font-size: 0.75rem;
        line-height: 1.4;
        margin-top: 20px;
    }
}

.jabil__cn__copyright a {
    color: $color-white;

    &:hover {
        border-bottom: 1px solid $color-white;
        color: $color-white;
    }
}
