@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

.buttonArea {
    padding-bottom: 30px;
    position: relative;

    &.buttonArea--paddingTop25 {
        padding-top: 25px;
    }

    &.buttonArea--paddingTop50 {
        padding-top: 50px;
    }

    &.buttonArea--sidePaddings0 {
        .wrap {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.buttonArea__title {
    padding-bottom: 0.5rem;

    &.buttonArea__title--bottomPadding0 {
        p {
            padding-bottom: 0;
        }
    }
}

.buttonArea__container {
    display: flex;
    flex-direction: column;

    @include breakpoint(md-lg) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    > .button {
        flex: 1;
    }

    .button {
        margin: 10px 0;
        padding: 8px 0;
        width: 100%;
        min-width: initial;

        @include breakpoint(md) {
            margin: 15px 0;
            padding: 8px 25px;
            width: 100%;
        }

        @include breakpoint(md-lg) {
            min-width: initial;
            max-width: 230px;
        }
    }

    &.buttonArea--marginBewteen {
        .button {
            @include breakpoint(md-lg) {
                margin: 0 15px;
            }
        }
    }

    &.justify-evenly {
        justify-content: space-evenly;
    }

    &.justify-around {
        justify-content: space-evenly;
    }

    &.justify-between {
        justify-content: space-between;
    }

    &.center {
        justify-content: center;
    }
}

@include forColors() using ($color, $value) {
    .columnsContainer--#{$color} {
        .buttonArea {
            padding: 30px;
        }
    }
}
