@use "../variables" as *;
@import "~photoswipe/src/css/default-skin/default-skin";
@import "~photoswipe/src/css/main";

.image {
    display: block;
    margin-bottom: 35px;

    img {
        max-width: 100%;
        margin: 0 auto;
        display: block;
        border: 1px solid $color-grey-cc;
    }

    .imageHero {
        border: none;
    }
}

.image--thumb {
    border: 1px solid $color-grey-cc;
}

.image--noBorder {
    img {
        border: none;
    }
}
