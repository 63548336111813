@use "../base";
@use "../mixins/breakpoint" as *;

@use "../modules/colorTint";
@use "../modules/columns";
@use "../modules/cta";
@use "../modules/image";
@use "../modules/page";
@use "../modules/richText";
@use "../modules/share";
@use "../modules/theme";
@use "../modules/title";
@use "../modules/titleHr";

.notFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80vh;
    align-items: center;
    text-align: center;

    h1,
    h2 {
        font-size: 1.7em;

        @include breakpoint(lg) {
            font-size: 3em;
        }
    }
}
