@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.page {
    z-index: 1;
    position: relative;
    width: 100%;
    padding-bottom: 1px; // avoid collapsing margins where no related content
    margin-bottom: -1px;
}

.page__container {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 35px;

    @include breakpoint(md) {
        margin-bottom: map-get($spacing, 4);
        display: flex;
        justify-content: space-between;
    }
}

.page__content {
    width: 100%;
}

.page__sidebar {
    margin-top: 40px;

    @include breakpoint(md) {
        margin-top: 0;
    }
}

.page__sidebar > *:first-child {
    margin-top: 0;
}

.page__sidebar > .button {
    min-width: 100%;
    margin-bottom: 0.5em;
}

.page--backgroundBlack {
    background: $color-black;
}

.page--light {
    background: $page-background;
    .page__container {
        background: transparent;
    }
}

.page--backgroundWhite {
  background: #fff;

  .page__container {
    padding: 2rem 0;
  }
}

.page--fill,
.page--backgroundWhite {
    .page__container {
        background: $page-background;
        padding: 2rem 0;
    }

    .page__container.wrap {
        width: calc(100% - 2em);

        @include breakpoint(md) {
            margin-left: auto;
            margin-right: auto;
            width: calc(100% - 4em);
        }

        @include breakpoint(xl) {
            margin-left: auto;
            margin-right: auto;
            width: calc(1400px - 4em);
        }

        @media screen and (min-width: $width-standard-screen-min) and (max-width: $width-content-wrap) {
            margin-left: auto;
            margin-right: auto;
            width: calc(100% - 4em);
        }
    }
}

.page--hasSidebar {
    .page__container {
        flex-direction: column;
        @include breakpoint(lg) {
            flex-direction: row;
        }
    }

    .page__content {
        @include breakpoint(lg) {
            width: 70%;
        }

        @include breakpoint(xl) {
            width: $width-main-content;
        }
    }

    .page__sidebar {
        @include breakpoint(lg) {
            width: 28%;
        }

        @include breakpoint(xl) {
            width: $width-main-sidebar;
        }
    }
}

.page--hasSidebar--wide {
    .page__container {
        flex-direction: column;
        margin-bottom: 0;
        padding-bottom: 60px;
        @include breakpoint(lg) {
            flex-direction: row;
        }
    }

    .page__content {
        @include breakpoint(lg) {
            width: 50%;
        }
    }

    .page__sidebar {
        @include breakpoint(lg) {
            width: 44%;
        }
    }
}

.page--fullWidth {
    .page__content {
        width: $width-full-page;
    }

    .page__sidebar {
        display: none;
    }
}

.theme--dark {
    .page--fillBg {
        background: $color-black;
    }

    .page--background {
        .title--light,
        .cta__title--light,
        .button--light {
            color: $color-black;
        }

        .button--light:hover {
            color: $color-white;
        }
    }
}

.page--backgroundTransparent {
    .page__container {
        background: transparent;
    }
}
