@use "../variables" as *;

html {
    background: #555;
}

body {
    background-color: $color-white;
    font-family: $font-lato;
    font-size: 16px;
    color: $color-grey-3c;
    overflow-x: hidden;
}

main {
    margin: 0;
}

a {
    color: $color-jabil-blue;
    transition: all ease-in-out 0.25s;
    text-decoration: none;

    &:hover {
        color: $color-jabil-dark-blue;
    }
}

hr {
    display: none;
}

h1 {
    font-size: 48px;
    font-weight: 400;
    margin-top: 0;
}

h2 {
    font-size: 48px;
    font-weight: 100;
}

h3 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
}

h4 {
    font-size: 18px;
    font-weight: 100;
    margin-bottom: 12px;
}

p {
    font-size: 18px;
    padding: 0;
    margin: 0;
}

.magnolia-editmode {
    header {
        z-index: 70000;
        position: relative;
    }
}

//push nav and footer back
.body--pushToBack {
    .nav {
        z-index: 0;
    }

    footer {
        z-index: 0;
    }

    .wrap.sectionSubnav {
        z-index: 0;
    }

    &.photoswipe {
        footer {
            z-index: 1;
        }
    }
}

input[type="search"] {
    -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.skipLinks {
    .skipLinks__link {
        position: absolute;
        padding: 0.5rem;
        background: rgba(0, 0, 0, 0.9);
        border-radius: 5px;
        top: -50px;
        left: 0;
        color: $color-white;
        transition: all 0.35s;
        z-index: 999;

        &:focus {
            top: 41px;
        }
    }
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
